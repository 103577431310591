import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { IDynamicObject } from "xa-generics";
import { EnvironmentContext } from "./Contexts/Environment.context";
import { AxiosResponse, AxiosError } from "axios";
import DefaultAxios from "axios";
import Environment from "./Models/Environment.model";
import Cookies from "js-cookie";
import Router from "./Router";
import Config from "./Config/Packages.config";
import "../node_modules/onemin-ui/dist/Scss/Fonts.css";
import "./Index.css";

const defaultLang: string | undefined = Cookies.get("lang");
Config(defaultLang);

DefaultAxios.get("/api/rest")
    .then((response: AxiosResponse<IDynamicObject>) => new Environment(response.data))
    .then((ENV_KEYS: Environment) => {
        const AppRoot = (
            <BrowserRouter>
                <EnvironmentContext.Provider value={ENV_KEYS}>
                    <Router />
                </EnvironmentContext.Provider>
            </BrowserRouter>
        );

        ReactDOM.render(AppRoot, document.getElementById("root"));
    })
    .catch((error: AxiosError) => {
        ReactDOM.render(
            <div>
                <h2>We're sorry but something has gone wrong.</h2>
                <p>Maybe copy the error message below to help us solve the issue:</p>
                <p>{error.message}</p>
                {console.error(error)}
            </div>,
            document.getElementById("root")
        );
    });
