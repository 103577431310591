import HU from "../Lang/HU.json";
import EN from "../Lang/EN.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { ErrorSettings } from "xa-error-with-lang";

export default function Config(defaultLanguage: string = "hu") {
    ErrorSettings.setTexts("warning", "error", "close", "unknown_error_type");

    i18n.use(initReactI18next).init({
        resources: {
            hu: {
                translation: HU
            },
            en: {
                translation: EN
            }
        },
        lng: defaultLanguage,
        fallbackLng: "hu",
        interpolation: {
            escapeValue: true
        }
    });
}
