import RestaurantBlock from "../../RestaurantBlock/View/RestaurantBlock.view";
import FitEbed from "../../../Resources/ebed_menu_gomb.png";
import FitAlacart from "../../../Resources/alacart_menu_gomb.png";
export interface IMainPageViewProps {}

const MainPageView: React.FC<IMainPageViewProps> = (props) => {
    return (
        <div className="main-page">
            {/* <header className="main-page-header">
                <HeaderBlock></HeaderBlock>
            </header> */}
            <footer className="main-page-footer">
                <RestaurantBlock
                    backgournd_url={FitEbed}
                    link="https://ebed.fitandfinehouse.hu"
                    className="align-right"
                />

                <RestaurantBlock
                    backgournd_url={FitAlacart}
                    link="https://etlap.fitandfinehouse.hu"
                    className="align-left"
                />

                {/* <RestaurantBlock
                    name="Ünnepi Hidegtálrendelés"
                    address=""
                    phone="06 30 325 3256"
                    link="https://karacsony.mealbox.hu/"
                /> */}
            </footer>
        </div>
    );
};

export default MainPageView;
