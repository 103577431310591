export interface IRestaurantViewProps {
    link: string;
    backgournd_url: string;
    className: string;
}

const RestaurantBlock: React.FC<IRestaurantViewProps> = (props) => {
    const handleClick = () => {
        window.open(props.link);
    };

    const classes: string[] = ["restaurant-block", props.className];

    return (
        <div
            onClick={handleClick}
            className={classes.join(" ")}
            style={{ backgroundImage: `url(${props.backgournd_url})` }}
        ></div>
    );
};

export default RestaurantBlock;
