import Navigation from "../../Navigation/View/Navigation.view";

export interface ILayoutViewProps {}

const LayoutView: React.FC<ILayoutViewProps> = (props) => {
    return (
        <>
            <Navigation />
            {props.children}
        </>
    );
};

export default LayoutView;
