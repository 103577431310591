import React, { createContext } from "react";
import Environment from "../Models/Environment.model";

const def: Environment = new Environment();

/**
 * ## Environment variable context
 *
 * This context stores all the environment variables
 * that return from the API on the first render.
 *
 * This is set only once in the project, not counting its initial empty state.
 */
export const EnvironmentContext: React.Context<Environment> = createContext<Environment>(def);

EnvironmentContext.displayName = "EnvironmentContext";
