export interface INavigationProps {}

const Navigation: React.FC<INavigationProps> = (props) => {
    return (
        <div className="navbar">
            {/* TODO add the orange box etc to the navbar and continue
            with the rest of the page in the MainPage component */}
        </div>
    );
};

export default Navigation;
